<template>
  <div class="property-wrapper">
    <template v-if="controls.length == 1">
      <ControlBaseProperties v-model="control" />
      <ControlSpecificPanel :control="control">
        <div class="form-group" v-if="control.synopticComponent">
          <label for="editor-value">{{ $t("value") }}</label>
          <RichText
            v-model="text"
            :contentStyle="control.synopticComponent.style"
            data-testid="text"
          />
        </div>
      </ControlSpecificPanel>
    </template>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { isEqual } from "lodash";
import ControlBaseProperties from "./control-base-properties";
import BaseControl from "./base-control.vue";
import ControlSpecificPanel from "@/components/editor/control-specific-panel.vue";
import RichText from "@/components/editor/rich-text";
export default {
  name: "StaticLabel",
  components: { ControlBaseProperties, ControlSpecificPanel, RichText },
  extends: BaseControl,
  data() {
    return {
      control: {}
    };
  },
  computed: {
    text: {
      set(value) {
        this.control.synopticComponent.value = value;
      },
      get() {
        return this.control.synopticComponent.value;
      }
    }
  },
  watch: {
    control: {
      deep: true,
      handler(val) {
        this.updateControl({
          id: val.id,
          control: {
            ...val,
            synopticComponent: {
              ...val.synopticComponent,
              value: val.synopticComponent.value
            }
          },
          noMerge: true
        });
      }
    },
    controls: {
      deep: true,
      handler(val) {
        let n = JSON.parse(JSON.stringify(val[0]));
        let o = JSON.parse(JSON.stringify(this.control));
        if (!isEqual(n, o)) {
          this.control = JSON.parse(JSON.stringify(n));
        }
      }
    }
  },
  methods: {
    ...mapActions("synoptic", ["updateControl"])
  },
  mounted() {
    this.control = JSON.parse(JSON.stringify(this.controls[0]));
  }
};
</script>
<style scoped>
#editor-value {
  resize: vertical;
}
label {
  margin-top: 0.7em;
  margin-bottom: 0.7em;
}
</style>
